<template>
  <div>
    <v-list>
      <v-list-tile
        avatar
        :key="session.id"
        v-for="session in bookings.data"
        :to="{
          name: 'BookingsView',
          params: { id: session.id },
        }"
      >
        <v-list-tile-avatar>
          <v-icon v-if="session.has_failed_jobs" color="error">error</v-icon>
          <v-icon v-else-if="session.has_incomplete_jobs" color="warning"
            >hourglass_top</v-icon
          >
          <v-icon v-else-if="session.approved_by" color="success"
            >done_all</v-icon
          >
          <v-icon v-else-if="session.state === 'closed'" color="info"
            >lock</v-icon
          >
          <v-icon v-else-if="session.state === 'open'">lock_open</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title>
            {{ session.name }}
            <span v-if="session.bookings_count"
              >({{ session.bookings_count }} Einträge)</span
            >
            <span v-else>(neu)</span>
          </v-list-tile-title>
          <v-list-tile-sub-title v-if="showTimeRange">
            <template v-if="session.active">
              Gestartet:
              {{ session.created_at | formatDate }}
            </template>
            <template v-else>
              Zeitraum:
              {{ session.created_at | formatDate }}
              -
              {{ session.closed_at | formatDate }}
            </template>
          </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>

      <v-list-tile v-if="bookings.data.length === 0">
        <v-list-tile-content>
          <v-list-tile-title> Keine Buchungsmappe gefunden </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
    <div class="text-xs-center py-3">
      <v-pagination
        :value="bookings.current_page"
        :length="bookings.last_page"
        :total-visible="10"
        @input="(value) => $emit('getPage', value)"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import formatDate from 'date-fns/format'

export default {
  name: 'BookingsListing',
  props: {
    bookings: {
      type: Object,
      required: true,
    },
    showTimeRange: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    formatDate(date) {
      return formatDate(new Date(date), 'DD.MM.YYYY (HH:mm [Uhr])')
    },
  },
}
</script>

<style scoped></style>
