<template>
  <app-layout>
    <v-container grid-list-md>
      <v-layout>
        <v-flex>
          <CompanySelector class="pb-2" />

          <v-card class="mb-3">
            <v-card-title>
              <h3>Buchungsmappen</h3>
              <v-spacer />
              <div class="d-flex">
                <v-switch class="mb-0" label="Archiviert" v-model="archived" />
              </div>
            </v-card-title>
            <v-divider />

            <BookingsListing
              v-if="bookingSessions"
              show-time-range
              :bookings="bookingSessions"
              @getPage="loadBookingsData"
            />
          </v-card>

          <v-card class="mb-3">
            <v-card-title>
              <h3>Buchungsmappen Vorauszahlungen</h3>
              <v-spacer />
            </v-card-title>
            <v-divider />

            <v-card-text>
              <v-container grid-list-md class="pa-0">
                <v-layout row>
                  <v-flex xs2>
                    <v-select
                      box
                      v-model="quartal"
                      :items="[1, 2, 3, 4]"
                      label="Quartal"
                    />
                  </v-flex>
                  <v-flex xs10>
                    <yearpicker v-model="year" label="Jahr" />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                :loading="prepaymentBookingsDataLoading"
                @click="createPrepaymentSession"
                >Export</v-btn
              >
            </v-card-actions>
            <v-divider />

            <BookingsListing
              v-if="prepaymentBookingSessions"
              title="Buchungsmappen Vorauszahlungen"
              :bookings="prepaymentBookingSessions"
              @getPage="loadPrepaymentBookingsData"
            />
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </app-layout>
</template>

<script>
import bookingsApi from '@/api/bookingsApi'
import AppLayout from '@/components/AppLayout'
import { mapGetters } from 'vuex'
import BookingsListing from '@/pages/bookings/BookingsListing.vue'
import CompanySelector from '@/components/CompanySelector.vue'
import Yearpicker from '@/components/dates/Yearpicker.vue'

export default {
  name: 'BookingsOverview',
  components: {
    Yearpicker,
    CompanySelector,
    BookingsListing,
    AppLayout,
  },
  data() {
    return {
      quartal: 1,
      year: null,
      prepaymentBookingsDataLoading: false,
      bookingSessions: null,
      prepaymentBookingSessions: null,
      archived: false,
    }
  },
  computed: {
    ...mapGetters(['currentCompany']),
  },
  methods: {
    loadInitialData() {
      this.loadPrepaymentBookingsData(1)
      this.loadBookingsData(1)
    },

    async loadBookingsData(page) {
      this.bookingSessions = await bookingsApi.getAll(
        this.currentCompany.id,
        'bookings',
        page,
        this.archived
      )
    },
    async loadPrepaymentBookingsData(page) {
      this.prepaymentBookingSessions = await bookingsApi.getAll(
        this.currentCompany.id,
        'prepaymentBookings',
        page
      )
    },
    async createPrepaymentSession() {
      try {
        this.prepaymentBookingsDataLoading = true
        await bookingsApi.createPrepaymentSession(
          this.$store.getters.currentCompany.id,
          this.year,
          this.quartal - 1
        )
        await this.loadPrepaymentBookingsData(1)
      } catch (e) {
        this.$root.setSnackbar('error', e.errorObject)
      } finally {
        this.prepaymentBookingsDataLoading = false
      }
    },
  },

  watch: {
    currentCompany: {
      handler: 'loadInitialData',
      immediate: true,
    },
    archived: {
      handler() {
        this.loadBookingsData(1)
      },
    },
  },
}
</script>

<style scoped></style>
